<template>
  <head-panel>
    <template v-slot:body>
      Категорія <span v-if="category"> {{ category.name }}</span></template>
  </head-panel>

  <div class="wrapper">
    <ul class="nav nav-pills">
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='main'?'active':''" aria-current="page"
           @click="show('main')">Основне</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='meta'?'active':''" @click="show('meta')" v-if="category">Мета</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='shortcut'?'active':''" @click="show('shortcut')" v-if="category">Розділи
          каталогу</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='filter'?'active':''" @click="show('filter')" v-if="category">Фільтри
          каталогу</a>
      </li>
    </ul>

    <hr/>

    <form @submit.prevent="submit">

      <div class="tab" v-if="tab==='main'">
        <Alert ref="alert"/>
        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Ідентифікатор</div>
          <div class="col-lg-8">
            <input type="text" class="form-control" v-model="form.name" :disabled="!!category"
                   :class="{ 'is-invalid' : v$.form.name.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Статус</div>
          <div class="col-lg-8">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                     v-model="form.status">
              <label class="form-check-label" for="flexSwitchCheckChecked">
                Статус категорії
              </label>
            </div>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Топ</div>
          <div class="col-lg-8">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                     v-model="form.top">
              <label class="form-check-label" for="flexSwitchCheckChecked">Дозволяє відобразити категорію на
                головній</label>
            </div>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Превью</div>
          <div class="col-lg-8">
            <div v-if="!form.preview">
              <input class="form-control" type="file" accept="image/png, image/jpeg" id="preview" ref="preview"
                     v-on:change="e => uploadPreview(e)">
              <label for="preview" class="form-label mt-1">Використовується для топ брендів та пошуку. Формат jpg, png</label>
            </div>

            <span class="preview" v-else>
                  <img v-bind:src="$filters.image(form.preview, '180x180')" width="70" height="70"
                       class="img-thumbnail">
                   <svg @click="removePreview()" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                      <path
                          d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                    </svg>
              </span>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Головна категорія</div>
          <div class="col-lg-8">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchRoot"
                     v-model="form.isRoot" :disabled="!!category || form.parent">
              <label class="form-check-label" for="flexSwitchRoot">Дозволяє вказати як головну категорю. Не має
                бути нащадком категорії. Після збререження не підлягає зміні</label>
            </div>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Нащадок категорії</div>
          <div class="col-lg-8">
            <Multiselect
                :class="{ 'is-invalid' : v$.form.parent.$error}"
                v-model="form.parent"
                :options="parentOptions"
                :multiple="false"
                :searchable="true"
                @search-change="findParents"
                placeholder="Введіть назву категоріі"
                selectLabel=""
                label="label"
                track-by="name"
                :disabled="form.isRoot"
            >
              <template v-slot:option="{ option }">
                <span class="option__name">{{ option.label }}</span>
                <span class="option__path">{{ option.path }}</span>
              </template>
              <template #noOptions>
                Пошук категорій...
              </template>
              <template #noResult>
                Категорій не знайдено!
              </template>
            </Multiselect>
            <div class="invalid-feedback" v-for="error of v$.form.parent.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Тип пошуку</div>
          <div class="col-lg-8">

            <div class="form-check">
              <input class="form-check-input" v-model="form.selection" value="HIERARCHY" type="radio" name="selection"
                     id="selectionRadioDefault1">
              <label class="form-check-label" for="selectionRadioDefault1">
                Вибірка по категоії і всіх її нащадках
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" v-model="form.selection" value="MULTIPLE" type="radio" name="selection"
                     :disabled="form.isRoot || !form.parent"
                     id="selectionRadioDefault2">
              <label class="form-check-label" for="selectionRadioDefault2">
                Вибірка за вказаним списком категорій і їх нащадках
              </label>
            </div>

            <multiselect v-model="form.selectionCategories" class="mt-2"
                         :disabled="form.isRoot || !form.parent || form.selection!=='MULTIPLE'"
                         tag-placeholder="Виберіть категорію"
                         placeholder="Додайте категорії"
                         label="label"
                         track-by="name"
                         :class="{ 'is-invalid' : v$.form.selectionCategories.$error}"
                         :options="selectionOptions"
                         selectLabel=""
                         :multiple="true"
                         :taggable="false"
                         :searchable="true"
                         :showLabels="false"
                         @search-change="q => findCategories('selectionOptions', form.parent, q)">
              <template v-slot:option="{ option }">
                <span class="option__name">{{ option.label }}</span>
                <span class="option__path">{{ option.path }}</span>
              </template>
              <template #noOptions>
                Для пошуку введіть як мінім 3 символи назви категорії
              </template>
            </multiselect>

            <div class="invalid-feedback" v-for="error of v$.form.selectionCategories.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>

          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Переклади</div>
          <div class="col-lg-8">
            <locale-form v-model="form.locales"/>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Опис</div>
          <div class="col-lg-8">
            <QuillEditor theme="snow" toolbar="essential" v-model:content="form.description.default"
                         contentType="html"/>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn btn-primary">Зберегти</button>
          </div>
        </div>
      </div>

      <div class="tab" v-if="tab==='meta'">
        <Alert ref="alert"/>
        <meta-form v-model="form.meta" :with-label="true"/>
        <div class="row ms-2 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn btn-primary">Зберегти</button>
          </div>
        </div>
      </div>

    </form>

    <div class="tab" v-if="tab==='shortcut'">
      <edit-short-cut-tab/>
    </div>

    <div class="tab" v-if="tab==='filter'">
      <edit-filters-tab/>
    </div>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import LocaleForm from "../../components/LocaleForm";
import MetaForm from "../../components/MetaForm";
import Multiselect from 'vue-multiselect'
import {debounce} from "../../lib/Util";
import {useVuelidate} from "@vuelidate/core";
import {helpers, maxLength, required, requiredIf} from "@vuelidate/validators";
import EditShortCutTab from "./EditShortCutTab";
import EditFiltersTab from "./EditFiltersTab";
import Alert from "../../components/Alert.vue";
import {QuillEditor} from '@vueup/vue-quill'


export default {
  name: "Edit",
  components: {
    HeadPanel, ConfirmDialogue, LocaleForm, MetaForm, Multiselect, EditShortCutTab, EditFiltersTab, Alert, QuillEditor
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255),
          pattern: helpers.withMessage('Invalid identity format', (v) => v.match("^[a-z0-9\\-]+$"))
        },
        description: {},
        parent: {
          required: requiredIf(!this.form.isRoot),
        },
        selection: {
          required
        },
        selectionCategories: {
          required: requiredIf(this.form.selection === 'MULTIPLE'),
        },
        locales: {
          required
        },
        status: {
          required
        }
      }
    };
  },
  data() {
    return {
      category: null,
      parent: null,
      parentOptions: [],
      vocabularies: [],
      selectionOptions: [],
      form: {
        parent: null,
        name: null,
        status: false,
        description: {
          default: null
        },
        filters: null,
        shortCuts: null,
        selection: 'HIERARCHY',
        selectionCategories: null,
        isRoot: null,
        top: null,
        preview: null,
        locales: null,
        meta: null,
        path: null
      },
      tab: 'main'
    }
  },
  methods: {
    show: function (tab) {

      /**
       * If category was updated in separate component we must update current category
       */
      if (['main', 'meta'].includes(tab) && ['shortcut', 'filter'].includes(this.tab))
        this.fetch(this.$route.params.id);

      this.tab = tab;
    },
    fetch: function (id) {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/category/" + id)
          .then((res) => {
            this.category = res.content;
            this.form = this.category;
            this.form.status = this.category.status === 'ACTIVE';
            this.form.description = this.category.description ?? {default: null};
            this.fetchParent(this.category);

            if (this.form.selectionCategories && this.form.selectionCategories.length)
              this.fetchCategories('selectionCategories', this.form.selectionCategories);
          });
    },
    fetchParent(category) {

      if (!category.path) {
        this.parent = null;
        return;
      }

      const paths = this.category.path.split(":");

      if (paths.length < 2) {
        this.parent = null;
        return;
      }

      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/category/" + paths[paths.length - 2])
          .then((res) => {
            this.parent = res.content;
            this.form.parent = {
              label: this.$filters.locale(this.parent.locales),
              path: this.parent.path.replaceAll(":", " --> "),
              name: this.parent.name
            }
          });

    },
    findParents(query) {
      debounce(() => {
        Http
            .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/category?search=" + query.toLowerCase())
            .then((res) => {
              this.parentOptions = res.content.map(cat => {
                return {
                  label: this.$filters.locale(cat.locales),
                  path: cat.path.replaceAll(":", " --> "),
                  name: cat.name
                }
              });
            });
      }, 300)();
    },
    fetchCategories(filed, names = []) {
      Http
          .post(process.env.VUE_APP_CATALOG_SERVICE + "/manage/category/list", names)
          .then((res) => {

            this.form[filed] = res.content.map(category => {
              return {
                label: this.$filters.locale(category.locales),
                path: category.path.replaceAll(":", " --> "),
                name: category.name
              };
            });
          });
    },
    findCategories(filed, parent, query) {

      if (!query || !query.length)
        return;

      debounce(() => {
        Http
            .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/category?search=" + query.toLowerCase() + "&path=" + parent.path.replaceAll(' --> ', ':'))
            .then((res) => {
              this[filed] = res.content
                  .filter(category => {
                    return category.name !== parent.name && (!this.category || category.name !== this.category.name)
                  })
                  .map(category => {
                    return {
                      label: this.$filters.locale(category.locales),
                      path: category.path.replaceAll(":", " --> "),
                      name: category.name
                    }
                  });
            });
      }, 300)();
    },

    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      let path = this.form.name;

      if (!this.form.isRoot && this.form.parent)
        path = (this.form.parent.path + ' --> ' + this.form.name).replaceAll(' --> ', ':');

      let data = {
        name: this.form.name,
        status: this.form.status ? 'ACTIVE' : 'DISABLED',
        preview: this.form.preview,
        isRoot: this.form.isRoot,
        top: this.form.top,
        selection: this.form.selection,
        selectionCategories: this.form.selectionCategories ? this.form.selectionCategories.map(item => item.name) : [],
        locales: this.form.locales,
        meta: this.form.meta,
        path: path,
        filters: this.form.filters,
        shortCuts: this.form.shortCuts,
        description: this._description(this.form.description)
      };

      if (!this.category)
        Http
            .post(process.env.VUE_APP_CATALOG_SERVICE + "/manage/category", data)
            .then((res) => {
              this.$router.replace("/category/" + res.content.id);
              this.fetch(res.content.id);
              this.$refs.alert.success('Success');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.message);
            });
      else
        Http
            .put(process.env.VUE_APP_CATALOG_SERVICE + "/manage/category", data)
            .then(() => {
              this.fetch(this.category.name);
              this.$refs.alert.success('Success');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.message);
            });
    },
    uploadPreview: function (e) {
      const file = e.target.files[0];
      const fd = new FormData();
      fd.append('file', file, file.name);

      Http.post(process.env.VUE_APP_PIO_SERVICE + "/v1/upload/" + process.env.VUE_APP_PIO_BUCKET + "/multipart", fd)
          .then(res => {
            this.form.preview = res.content;
          });
    },
    removePreview: function () {
      this.form.preview = null;
    },
    _description: function (description) {
      return description && description.default && description.default.replace(/<\/?[^>]+(>|$)/g, "") ? description : null;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

.option__path {
  float: right;
}

</style>
