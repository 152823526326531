<template>
  <head-panel>
    <template v-slot:body>
      Тег <span v-if="tag"> {{ tag.name }}</span></template>
  </head-panel>

  <div class="wrapper">

    <Alert ref="alert"/>

    <form @submit.prevent="submit">

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Словник</div>
        <div class="col-lg-8">
          <select class="form-select" v-model="form.vocabulary" :disabled="!!tag"
                  :class="{ 'is-invalid' : v$.form.vocabulary.$error}">
            <option v-for="item in vocabularies" :key="item" v-bind:value="item.value">{{ item.label }}</option>
          </select>
          <div class="invalid-feedback" v-for="error of v$.form.vocabulary.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Ідентифікатор</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.name" :disabled="!!tag"
                 :class="{ 'is-invalid' : v$.form.name.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Статус</div>
        <div class="col-lg-8">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                   v-model="form.status">
            <label class="form-check-label" for="flexSwitchCheckChecked">
              Статус тегу
            </label>
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Позиція</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.weight" :class="{ 'is-invalid' : v$.form.weight.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.weight.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>



      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Превью</div>
        <div class="col-lg-8">
          <div v-if="!form.preview">
            <input class="form-control" type="file" accept="image/png, image/jpeg" id="preview" ref="preview"
                   v-on:change="e => uploadPreview(e)">
            <label for="preview" class="form-label mt-1">Використовується для топ брендів та пошуку. Формат jpg, png</label>
          </div>

          <span class="preview" v-else>
                  <img v-bind:src="$filters.image(form.preview, '180x180')" width="70" height="70"
                       class="img-thumbnail">
                   <svg @click="removePreview()" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                      <path
                          d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                    </svg>
              </span>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Переклади</div>

        <div class="col-lg-8">


          <locale-form v-model="form.locales"/>


        </div>

      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>

    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import Alert from "../../components/Alert";
import LocaleForm from "../../components/LocaleForm";
import {useVuelidate} from "@vuelidate/core";
import {helpers, maxLength, required, numeric} from "@vuelidate/validators";

export default {
  name: "Edit",
  components: {
    HeadPanel, ConfirmDialogue, Alert, LocaleForm
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);

    this.fetchVocabularies();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        vocabulary: {
          required,
          maxLength: maxLength(255),
          pattern: helpers.withMessage('Invalid name format', (v) => v.match("^[a-z0-9\\-]+$"))
        },
        name: {
          required,
          maxLength: maxLength(255),
          pattern: helpers.withMessage('Invalid name format', (v) => v.match("^[a-z0-9\\-]+$"))
        },
        weight: {
          numeric
        },
        status: {
          required
        }
      }
    };
  },
  data() {
    return {
      tag: null,
      vocabularies: [],
      form: {
        vocabulary: null,
        name: null,
        status: null,
        locales: null,
        preview: null,
        weight: null
      }
    }
  },
  methods: {
    fetch: function (id) {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/tag/" + id)
          .then((res) => {
            this.tag = res.content;
            this.form = this.tag;
            this.form.status = this.tag.status === 'ACTIVE';
          });
    },
    fetchVocabularies: function () {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/tag/vocabulary")
          .then((res) => {
            this.vocabularies = res.content
                .map(vocabulary => {
                  return {'value': vocabulary.id, 'label': vocabulary.id};
                });
          });
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      let data = {
        vocabulary: this.form.vocabulary,
        name: this.form.name,
        status: this.form.status ? 'ACTIVE' : 'DISABLED',
        locales: this.form.locales,
        preview: this.form.preview,
        weight: this.form.weight
      };

      if (!this.tag)
        Http
            .post(process.env.VUE_APP_CATALOG_SERVICE + "/manage/tag", data)
            .then((res) => {
              this.$router.replace("/tag/" + res.content.id);
              this.fetch(res.content.id);
              this.$refs.alert.success('Успішно збережено');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.message);
            });
      else
        Http
            .put(process.env.VUE_APP_CATALOG_SERVICE + "/manage/tag/" + this.$route.params.id, data)
            .then((res) => {
              this.fetch(res.content.id);
              this.$refs.alert.success('Успішно збережено');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.message);
            });
    },
    uploadPreview: function (e) {
      const file = e.target.files[0];
      const fd = new FormData();
      fd.append('file', file, file.name);

      Http.post(process.env.VUE_APP_PIO_SERVICE + "/v1/upload/" + process.env.VUE_APP_PIO_BUCKET + "/multipart", fd)
          .then(res => {
            this.form.preview = res.content;
          });
    },
    removePreview: function () {
      this.form.preview = null;
    }
  }
};
</script>

<style scoped>
.preview {
  float: left;
  margin-right: 10px;
  position: relative;
}

.preview svg {
  color: red;
  cursor: pointer;
  margin-left: 20px;
}
</style>
